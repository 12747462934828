//partials
@import './front/base/_appfront';

// custom styles
#page {
    margin-bottom: 3rem;
    figure {
        margin-top: 2rem;
        margin-bottom: 3rem;
        img {
            border-radius: $rounded;
        }
    }
}
/* formulaire CONTACT */
#form-99999 {
    .champs {
        margin-bottom: 1rem;
    }
    .form-control {
        height: 3rem;;    
    }  
    textarea.form-control {
        height: 8rem;
    }
}
/* FIN formulaire CONTACT */

/* formulaire LOGIN */
.contact__text {
    line-height: 1.5;
}
textarea.form-control {
    height: 4rem;
}
.mentions {
    font-size: rem(16px);
}
/* FIN formulaire LOGIN */